import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// Components
import { AboutHistory, DoctorsCount, HeroImage, OurTeam } from "@components";

const AboutUs = () => {
    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(relativePath: { eq: "about-us.jpeg" }) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );
    return (
        <>
            <HeroImage
                title={"About Us"}
                image={placeholder.childImageSharp.fluid}
            />
            <AboutHistory />
            {/* <DoctorsCount /> */}
            {/* <OurTeam /> */}
        </>
    );
};

export default AboutUs;
